import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import myConfiguredSanityClient from "../../sanityClient"
import imageUrlBuilder from "@sanity/image-url"
import ContactBox from "../components/contactBox"
import ScrollDownArrow from "../components/scrollDownArrow"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"

import Icon from "../assets/sis_circle.svg"

const builder = imageUrlBuilder(myConfiguredSanityClient)

const News = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityUpcommingEvents {
        title
        description
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
            url
          }
          hotspot {
            x
            y
          }
        }
      }
      sanityEmployees(_id: { eq: "employees" }) {
        employee {
          position
          name
          bio
          image {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
        }
      }
      sanityRules {
        fileArray {
          asset {
            url
            originalFilename
          }
        }
      }
      allSanityCalendar {
        edges {
          node {
            title
            _rawDescription

            calendarInfo {
              location
              date(formatString: "DD. MMMM YYYY | HH:mm", locale: "da_DK")
            }
          }
        }
      }
    }
  `)

  //   var today = new Date()
  //   var dd = String(today.getDate()).padStart(2, "0")
  //   var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  //   var yyyy = today.getFullYear()

  //   today = mm + "-" + dd + "-" + yyyy
  //   document.write(today)

  function getFocalPointsX(data) {
    let xValue = 0.5

    if (data.image.hotspot !== null) {
      xValue = data.image.hotspot.x
    }

    return xValue
  }

  function getFocalPointsY(data) {
    let yValue = 0.5

    if (data.image.hotspot !== null) {
      yValue = data.image.hotspot.y
    }

    return yValue
  }

  function urlFor(source) {
    return builder.image(source)
  }

  function introImageHasLoaded(e) {
    e.target.classList.add("showImage")
  }
  return (
    <Layout>
      <SEO
        title="Kommende aktiviteter"
        description={data.sanityUpcommingEvents.description}
      />

      <section className="news">
        <div className="introSection">
          <div className="introWrapper">
            <div className="imageWrapper">
              <img
                className="image"
                onLoad={introImageHasLoaded}
                src={urlFor(data.sanityUpcommingEvents.image.asset.url)
                  .focalPoint(
                    getFocalPointsX(data.sanityUpcommingEvents),
                    getFocalPointsY(data.sanityUpcommingEvents)
                  )
                  .width(500)
                  .height(500)
                  .fit("crop")
                  .auto("format")
                  .url(data.sanityUpcommingEvents.image.asset.fluid.src)}
                alt={data.sanityUpcommingEvents.title}
              />
              <Img
                fluid={data.sanityUpcommingEvents.image.asset.fluid}
                alt={data.sanityUpcommingEvents.title}
              />
              <Icon className="fadeinSVG" />
            </div>
            <div className="introText">
              <h1 className="titleFadeIn">
                {data.sanityUpcommingEvents.title}
              </h1>
              <p className="descriptionFadeIn">
                {data.sanityUpcommingEvents.description}
              </p>
              <div className="whiteSpace"></div>
            </div>
          </div>
          <ScrollDownArrow />
        </div>

        <div className="activitySectionPage">
          {data.allSanityCalendar.edges.map(function (event) {
            console.log(event)

            return <div className="activityGrid">{checkDate(event)}</div>
          })}

          <div class="custom-shape-divider-bottom-1592555946">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default News

function checkDate(event) {
  // Get date for the event

  let eventDate = event.node.calendarInfo.date

  let dateAsNumber = eventDate.split(".")[0]
  let monthAsString = eventDate.split(" ")[1]
  let monthAsNumber = getMonthNumber(monthAsString)
  let yearAsNumber = eventDate.split(" ")[2]

  let finalEventDate = yearAsNumber + "-" + monthAsNumber + "-" + dateAsNumber

  var today = new Date()
  var dd = String(today.getDate()).padStart(2, "0")
  var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  var yyyy = today.getFullYear()

  today = yyyy + "-" + mm + "-" + dd

  if (finalEventDate >= today) {
    return (
      <div className="activityList">
        <div className="activityInfo">
          <p className="linkTemp">
            {getRightTime(event.node.calendarInfo.date)}
          </p>
          <h3>{event.node.title}</h3>
          <BlockContent
            className="descriptionFadeIn"
            blocks={event.node._rawDescription}
            projectId="q2jj4zmh"
            dataset="production"
            serializers={serializers}
          />

          <div className="whiteSpace"></div>

          {/* <AniLink cover bg="#69b56b" className="buttonTemp">
            {data.sanityAnnualMeeting.button}
          </AniLink> */}
          <div className="whiteSpace"></div>
        </div>

        <div className="contactWrapper">
          <p className="signUp">Tilmeld</p>
          <p className="contactDate">
            {getRightTime(event.node.calendarInfo.date)}
          </p>
          <ContactBox data={event.node.title} name={"kommende-aktiviteter"} />
        </div>
      </div>
    )
  }
}

// 2020-11-19

function getMonthNumber(monthAsString) {
  if (monthAsString === "januar") {
    monthAsString = "01"
  }
  if (monthAsString === "februar") {
    monthAsString = "02"
  }
  if (monthAsString === "marts") {
    monthAsString = "03"
  }
  if (monthAsString === "april") {
    monthAsString = "04"
  }
  if (monthAsString === "maj") {
    monthAsString = "05"
  }
  if (monthAsString === "juni") {
    monthAsString = "06"
  }
  if (monthAsString === "juli") {
    monthAsString = "07"
  }
  if (monthAsString === "august") {
    monthAsString = "08"
  }
  if (monthAsString === "september") {
    monthAsString = "09"
  }
  if (monthAsString === "oktober") {
    monthAsString = "10"
  }
  if (monthAsString === "november") {
    monthAsString = "11"
  }
  if (monthAsString === "december") {
    monthAsString = "12"
  }
  return monthAsString
}

function getRightTime(date) {
  console.log(date)
  let firstHalf = date.split("|")[0]
  let secondHalf = date.split("|")[1]
  let secondHalfDivided = secondHalf.split(":")[0]
  let newHour = parseInt(secondHalfDivided) + 2
  let secondHalfDividedEnding = secondHalf.split(":")[1]
  return firstHalf + " | " + newHour + ":" + secondHalfDividedEnding
}
