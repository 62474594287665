import React from "react"

const scrollDownArrow = ({ data }) => (
  <div class="box">
    <span></span>
    <span></span>
    <span></span>
  </div>
)

export default scrollDownArrow
